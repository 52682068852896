





















import Vue from "vue";

import Footer from "@/components/navigation-footer.vue";
export default Vue.extend({
  components: { Footer },
  mounted: function () {
    this.$store.dispatch("SetLogoStyle", { color: "dark" });
  },
  computed:{
    nextLink: function () {
      return (+this.$route.params.slide + 1).toString();
    },
  }
});
