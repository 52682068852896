import { render, staticRenderFns } from "./5.vue?vue&type=template&id=f6b826ac&scoped=true&"
import script from "./5.vue?vue&type=script&lang=ts&"
export * from "./5.vue?vue&type=script&lang=ts&"
import style0 from "./5.vue?vue&type=style&index=0&id=f6b826ac&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f6b826ac",
  null
  
)

export default component.exports